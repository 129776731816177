.App {
  text-align: center;
}

*{
  user-select: none;
  font-family: 'Bantayog-regular' !important;
}


.font-regular{
  font-family: 'Bantayog-regular' !important;
}
.font-light{
  font-family: 'Bantayog-light' !important;
}
.font-semilight{
  font-family: 'Bantayog-semilight' !important;
}

@font-face {
  font-family: "Bantayog-regular";
  src:
    local("Bantayog"),
    url("font/Bantayog-Regular.otf") format("opentype") ,
    url("font/Bantayog-Regular.woff2") format("woff2"),
    url("font/Bantayog-Regular.woff") format("woff");
}
@font-face {
  font-family: "Bantayog-light";
  src:
    local("Bantayog"),
    url("font/Bantayog-Light.otf") format("opentype") ,
    url("font/Bantayog-Light.woff2") format("woff2"),
    url("font/Bantayog-Light.woff") format("woff");
}
@font-face {
  font-family: "Bantayog-semilight";
  src:
    local("Bantayog"),
    url("font/Bantayog-Semilight.otf") format("opentype") ,
    url("font/Bantayog-Semilight.woff2") format("woff2"),
    url("font/Bantayog-Semilight.woff") format("woff");
}

.noRightClick{
  pointer-events: none !important;
}
.contenitore{
  position: relative;
}
.divSovrapposto{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: transparent ;
  z-index: 2;
}
.divSovrapposto2{
  position: absolute;
  width: auto;
  height: auto;
  top: 0px;
  left: 0px;
  background-color: red ;
  z-index: 2;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.img-hover-zoom {
  overflow: hidden;
}
.img-hover-zoom img {
  transition: transform .3s ease;
}
.img-hover-zoom:hover img {
  transform: scale(1.03);
}

* {
  font-family: 'Teko', normal;
}
.myModal{
  width: 100vw !important;
  background-color: rgba(1, 1, 1, 0) !important;
  padding: 0 !important;
  max-width: 0;
}
.modal-content{
  padding: 0 !important;
  border-width: 0 !important;
  background-color: transparent !important;
}
.modal-dialog{
}




/* BUTTONS
*/
.activeButton{
  white-space: nowrap;
  color: white;
  background-color: #5E406E;
  border: 3px solid white;
   border-radius: 2px;
   cursor: pointer;
}

.disabledButton{
  white-space: nowrap;
  color: white;
  background-color: transparent;
  border: 3px solid white;
  border-radius: 2px;
  cursor: pointer
}

.FilterRadio{
  visibility: hidden;
}
  
.FilterLabel{
  white-space: nowrap;
  color: white;
  background-color: transparent;
  border: 3px solid white;
  border-radius: 2px;
  cursor: pointer
}

.ButtonGroup input[type='radio']:checked + label{
  white-space: nowrap;
  color: white;
  background-color: #5E406E;
  border: 3px solid white;
   border-radius: 2px;
   cursor: pointer;
}